import { ReadTransaction } from 'replicache';
import { z, ZodObject, ZodRawShape, ZodType } from 'zod';

type Key = string | number | symbol;
type Primitive = string | number | symbol | null;

type RecordWithGroup<T, K extends Key, V extends Primitive> = {
  [P in K]: V;
} & {
  [P in K]: T[];
};

export function groupJoin<A, B, K extends string>(
  as: A[],
  bs: B[],
  on: (a: A, b: B) => boolean,
  groupField: K,
  options?: {
    having?: (result: A & { [P in K]: B[] }) => boolean;
    orderBy?: (b1: B, b2: B) => number;
  }
): (A & { [P in K]: B[] })[] {
  const results = as.map((a) => {
    const joinedRecords = bs.filter((b) => on(a, b));

    if (options?.orderBy) {
      joinedRecords.sort(options.orderBy);
    }

    return { ...a, [groupField]: joinedRecords } as A & {
      [P in K]: B[];
    };
  });

  const having = options?.having;
  return having ? results.filter((x) => having(x)) : results;
}

export function groupBy<T, K extends Key, V extends Primitive>(
  ts: T[],
  on: (t: T) => V,
  groupKeyName: K,
  groupValueName: K,
  options?: {
    having?: (group: T[]) => boolean;
    orderBy?: (t1: T, t2: T) => number;
  }
): RecordWithGroup<T, K, V>[] {
  const groupMap = new Map<V, T[]>();

  ts.forEach((t) => {
    const groupId = on(t);
    let group = groupMap.get(groupId);
    if (!group) {
      group = [];
      groupMap.set(groupId, group);
    }
    group.push(t);
  });

  const having = options?.having;
  if (having) {
    groupMap.forEach((group, key) => {
      if (!having(group)) {
        groupMap.delete(key);
      }
    });
  }

  const orderBy = options?.orderBy;
  if (orderBy) {
    groupMap.forEach((group) => {
      group.sort(orderBy);
    });
  }

  return Array.from(groupMap, ([key, value]) => ({
    [groupKeyName]: key,
    [groupValueName]: value,
  })) as RecordWithGroup<T, K, V>[];
}

export function compact<T>(ts: (T | null | undefined)[]): T[] {
  return ts.filter((t): t is T => t !== null && t !== undefined);
}

export class DB {
  constructor(private tx: ReadTransaction) {}

  from<T extends ZodRawShape>(prefix: string, schema: ZodObject<T>) {
    return new From(this.tx, prefix, schema);
  }
}

class From<T extends ZodRawShape> {
  filters: ((row: any) => boolean)[] = [];

  constructor(
    private tx: ReadTransaction,
    public prefix: string,
    public schema: any
  ) {}

  where(filter: (row: z.infer<typeof this.schema>) => boolean) {
    this.filters.push(filter);
    return this;
  }

  innerJoin(
    prefix: string,
    schema: any,
    on: (left: any, right: any) => boolean
  ) {
    return new InnerJoin(this.tx, this, new From(this.tx, prefix, schema), on);
  }

  async execute(): Promise<Array<z.infer<ZodType<T>>>> {
    const rows = this.tx.scan({ prefix: this.prefix + '/' });
    let parsedRows: Array<unknown> = [];
    for await (const row of rows) {
      const parsedRow = this.schema.parse(row);
      if (this.filters.every((filter) => filter(parsedRow))) {
        parsedRows.push(parsedRow);
      }
    }

    return parsedRows as any;
  }

  async _execute() {
    return await this.execute();
  }
}

class InnerJoin {
  constructor(
    private tx: ReadTransaction,
    private left: any,
    private right: any,
    private on: (left: any, right: any) => boolean
  ) {}

  filters: ((row: any) => boolean)[] = [];

  innerJoin(
    prefix: string,
    schema: any,
    on: (left: any, right: any) => boolean
  ) {
    return new InnerJoin(this.tx, this, new From(this.tx, prefix, schema), on);
  }

  where(filter: (row: any) => boolean) {
    this.filters.push(filter);
    return this;
  }

  async execute<T extends ZodRawShape>(
    schema: ZodObject<T>
  ): Promise<Array<z.infer<ZodObject<T>>>> {
    const leftRows = await this.left._execute();
    const rightRows = await this.right._execute();
    let joinedRows: Array<unknown> = [];

    for (const leftRow of leftRows) {
      for (const rightRow of rightRows) {
        if (this.on(leftRow, rightRow)) {
          const row = {
            ...leftRow,
            [this.right.prefix]: rightRow,
          };
          if (this.filters.every((filter) => filter(row))) {
            joinedRows.push(schema ? schema.parse(row) : row);
          }
        }
      }
    }

    return joinedRows as any;
  }

  private async _execute() {
    const leftRows = await this.left.execute();
    const rightRows = await this.right.execute();
    const joinedRows: Array<unknown> = [];

    for (const leftRow of leftRows) {
      for (const rightRow of rightRows) {
        if (this.on(leftRow, rightRow)) {
          const row = {
            ...leftRow,
            [this.right.prefix]: rightRow,
          };
          if (this.filters.every((filter) => filter(row))) {
            joinedRows.push(row);
          }
        }
      }
    }
    return joinedRows;
  }
}
