import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { Submittal } from './submittal';
import { SubmittalApprover } from './submittal-approver';

export class SubmittalItem extends ApplicationRecord {
  id: string;
  createdAt: string;
  updatedAt: string;
  submittalId: string;

  static prefix = 'submittalItems' as const;

  static schema = z.object({
    id: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    submittalId: z.string(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.submittalId = this.attribute('submittalId');
  }

  get approvers() {
    return this.hasMany(SubmittalApprover, 'submittalItemId');
  }

  get submittal() {
    return this.belongsTo(Submittal, this.submittalId);
  }
}
