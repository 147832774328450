import { ReadonlyJSONObject } from '@rocicorp/rails';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { ObjectPool } from '../object-pool';
import { Organization } from '../organization/model';
import { Project } from '../project';

export class DrawingArea extends ApplicationRecord {
  projectId: string;
  organizationId: string;
  createdAt: string;
  updatedAt: string;

  static prefix = 'drawingAreas' as const;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);
    this.projectId = this.attribute('projectId', z.string());
    this.organizationId = this.attribute('organizationId', z.string());
    this.createdAt = this.attribute(
      'createdAt',
      z.string().datetime({ offset: true })
    );
    this.updatedAt = this.attribute(
      'updatedAt',
      z.string().datetime({ offset: true })
    );
  }

  get project() {
    return this.belongsTo(Project, this.projectId);
  }

  get organization() {
    return this.belongsTo(Organization, this.organizationId);
  }
}
