import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { Submittal } from './submittal';

export class SubmittalDocument extends ApplicationRecord {
  id: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  submittalId?: string | null | undefined;
  pdfId?: string | null | undefined;
  fileUrl?: string | null | undefined;

  static prefix = 'submittalDocuments' as const;

  static schema = z.object({
    id: z.string(),
    title: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    submittalId: z.string().nullish(),
    pdfId: z.string().nullish(),
    fileUrl: z.string().nullish(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.title = this.attribute('title');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.submittalId = this.attribute('submittalId');
    this.pdfId = this.attribute('pdfId');
    this.fileUrl = this.attribute('fileUrl');
  }

  get submittal() {
    // optional since these are created before the submittal is created
    return this.belongsToOptional(Submittal, this.submittalId);
  }
}
