import invariant from '@/lib/invariant';
import { uniq } from 'lodash';
import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { ObjectPool } from '../object-pool';
import { OrganizationMembership } from '../organization-membership/model';
import { Organization } from '../organization/model';
import { Project } from '../project';

export class User extends ApplicationRecord {
  email: string;
  firstName: string;
  lastName: string;
  otpEnabled: boolean;
  verified: boolean;
  createdAt: string;
  updatedAt: string;
  avatarUrl?: string | null | undefined;

  static prefix = 'users' as const;

  static schema = z.object({
    email: z.string().email(),
    firstName: z.string(),
    lastName: z.string(),
    otpEnabled: z.boolean(),
    verified: z.boolean(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    avatarUrl: z.string().nullish(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.email = this.attribute('email');
    this.firstName = this.attribute('firstName');
    this.lastName = this.attribute('lastName');
    this.otpEnabled = this.attribute('otpEnabled');
    this.verified = this.attribute('verified');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.avatarUrl = this.attribute('avatarUrl');
  }

  static usersForSelect(
    objectPool: ObjectPool,
    organizationId: string,
    projectId: string
  ) {
    const organization = objectPool.find(Organization, organizationId);
    const project = objectPool.find(Project, projectId);

    invariant(organization, 'Organization not found');
    invariant(project, 'Project not found');

    const organizationUsers = organization.organizationMemberships.map(
      (membership) => membership.user
    );

    const externalUsers = uniq(
      project.projectMemberships.flatMap((projectMembership) => {
        return projectMembership.company?.users;
      })
    );

    return [...organizationUsers, ...externalUsers];
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  get organizationMemberships() {
    return this.hasMany(OrganizationMembership, 'userId');
  }
}
