import { ReadonlyJSONObject } from '@rocicorp/rails';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { Company } from '../company/model';
import { ObjectPool, RegisteredModelPrefix } from '../object-pool';
import { Project } from '../project';

export class ProjectMembership extends ApplicationRecord {
  projectId: string;
  companyId: string;
  createdAt: string;
  updatedAt: string;

  static prefix = 'projectMemberships' as const;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.projectId = this.attribute('projectId', z.string());
    this.companyId = this.attribute('companyId', z.string());
    this.createdAt = this.attribute('createdAt', z.string().datetime());
    this.updatedAt = this.attribute('updatedAt', z.string().datetime());
  }

  get company() {
    return this.belongsToOptional(Company, this.companyId);
  }

  get project() {
    return this.belongsToOptional(Project, this.projectId);
  }
}
