import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { Submittal } from './submittal';
import { User } from './user/model';

export class SubmittalSubscriber extends ApplicationRecord {
  id: string;
  createdAt: string;
  updatedAt: string;
  submittalId: string;
  userId: string;

  static prefix = 'submittalSubscribers' as const;

  static schema = z.object({
    id: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    submittalId: z.string(),
    userId: z.string(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.submittalId = this.attribute('submittalId');
    this.userId = this.attribute('userId');
  }

  get submittal() {
    return this.belongsTo(Submittal, this.submittalId);
  }

  get user() {
    return this.belongsTo(User, this.userId);
  }
}
