import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { DrawingArea } from './drawing-area/model';
import { DrawingSet } from './drawing-set/model';
import { ObjectPool } from './object-pool';
import { Organization } from './organization/model';
import { ProjectMembership } from './project_membership/model';

export class Project extends ApplicationRecord {
  id: string;
  name: string;
  number?: string | null;
  organizationId: string;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
  country?: string | null;
  createdAt: string;
  updatedAt: string;
  coverPhotoUrl?: string | null | undefined;

  static prefix = 'projects' as const;

  static schema = z.object({
    id: z.string(),
    name: z.string(),
    number: z.string().nullish(),
    address1: z.string().nullish(),
    address2: z.string().nullish(),
    city: z.string().nullish(),
    state: z.string().nullish(),
    postalCode: z.string().nullish(),
    country: z.string().nullish(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    coverPhotoUrl: z.string().nullish(),
    organizationId: z.string(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.name = this.attribute('name');
    this.number = this.attribute('number');
    this.organizationId = this.attribute('organizationId');
    this.address1 = this.attribute('address1');
    this.address2 = this.attribute('address2');
    this.city = this.attribute('city');
    this.state = this.attribute('state');
    this.postalCode = this.attribute('postalCode');
    this.country = this.attribute('country');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.coverPhotoUrl = this.attribute('coverPhotoUrl');
  }

  get drawingSets() {
    return this.hasMany(DrawingSet, 'projectId');
  }

  get organization() {
    return this.belongsToOptional(Organization, this.organizationId);
  }

  get projectMemberships() {
    return this.hasMany(ProjectMembership, 'projectId');
  }

  get drawingAreas() {
    return this.hasMany(DrawingArea, 'projectId');
  }
}
