import { ReadonlyJSONObject } from '@rocicorp/rails';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { DrawingArea } from '../drawing-area/model';
import { DrawingSetPage } from '../drawing-set-page/model';
import { ObjectPool, RegisteredModelPrefix } from '../object-pool';

export class DrawingSet extends ApplicationRecord {
  id: string;
  name: string;
  drawingAreaId: string;

  static prefix = 'drawingSets' as const;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);
    this.id = this.attribute('id', z.string());
    this.name = this.attribute('name', z.string());
    this.drawingAreaId = this.attribute('drawingAreaId', z.string());
  }

  get drawingArea() {
    return this.belongsTo(DrawingArea, this.drawingAreaId);
  }

  get drawingSetPages() {
    return this.hasMany(DrawingSetPage, 'drawingSetId');
  }
}
