import { uniq } from 'lodash';
import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { Submittal } from './submittal';
import { SubmittalApprover } from './submittal-approver';

export const submittalStageStatuses = [
  'approved',
  'rejected',
  'in_progress',
  'blocked',
] as const;
export type SubmittalStageStatus = (typeof submittalStageStatuses)[number];
export const submittalStageApprovalConditions = ['all', 'one'] as const;
export type SubmittalStageApprovalCondition =
  (typeof submittalStageApprovalConditions)[number];
export const submittalStageApprovalConditionLabels = {
  all: 'All responses required to progress',
  one: 'At least one response required to progress',
};
export class SubmittalStage extends ApplicationRecord {
  id: string;
  dueOn: string;
  createdAt: string;
  updatedAt: string;
  submittalId: string;
  approvalCondition: SubmittalStageApprovalCondition;
  status: SubmittalStageStatus;

  static prefix = 'submittalStages' as const;

  static schema = z.object({
    id: z.string(),
    dueOn: z.string().date(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    submittalId: z.string(),
    approvalCondition: z.enum(submittalStageApprovalConditions),
    status: z.enum(submittalStageStatuses),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.dueOn = this.attribute('dueOn');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.submittalId = this.attribute('submittalId');
    this.approvalCondition = this.attribute(
      'approvalCondition',
      z.enum(submittalStageApprovalConditions)
    );
    this.status = this.attribute('status', z.enum(submittalStageStatuses));
  }

  get submittal() {
    return this.belongsTo(Submittal, this.submittalId);
  }

  get approvers() {
    return this.hasMany(SubmittalApprover, 'submittalStageId');
  }

  get approversUsers() {
    return uniq(this.approvers.map((a) => a.user));
  }

  get rejected() {
    return this.approvers.some((a) => a.rejected);
  }

  get approved() {
    if (this.rejected) return false;

    if (this.approvalCondition === 'one') {
      return this.approvers.some((a) => a.approved);
    }

    return this.approvers.every((a) => a.approved);
  }
}
