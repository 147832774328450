import { ReadonlyJSONObject } from '@rocicorp/rails';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { ObjectPool } from '../object-pool';

export class Topic extends ApplicationRecord {
  title: string;
  status: 'new' | 'draft' | 'editing' | 'converted' | 'archived';
  type: 'topic' | 'punch_list' | 'rfi' | 'submittal';
  companyAccessible: boolean;
  organizationAccessible: boolean;
  createdAt: string;
  updatedAt: string;

  static prefix = 'issues' as const;

  static schema = z.object({
    id: z.string(),
    title: z.string(),
    status: z.enum(['new', 'draft', 'editing', 'converted', 'archived']),
    type: z.enum(['topic', 'punch_list', 'rfi', 'submittal']),
    companyAccessible: z.boolean(),
    organizationAccessible: z.boolean(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);
    this.title = this.attribute('title', z.string());
    this.status = this.attribute(
      'status',
      z.enum(['new', 'draft', 'editing', 'converted', 'archived'])
    );
    this.type = this.attribute(
      'type',
      z.enum(['topic', 'punch_list', 'rfi', 'submittal'])
    );
    this.companyAccessible = this.attribute('companyAccessible', z.boolean());
    this.organizationAccessible = this.attribute(
      'organizationAccessible',
      z.boolean()
    );
    this.createdAt = this.attribute('createdAt', z.string().datetime());
    this.updatedAt = this.attribute('updatedAt', z.string().datetime());
  }
}
