import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { CompanyMembership } from '../company_membership/model';
import { ObjectPool, RegisteredModelPrefix } from '../object-pool';
import { OrganizationMembership } from '../organization-membership/model';
import { Project } from '../project';

export class Organization extends ApplicationRecord {
  name: string;
  createdAt: string;
  updatedAt: string;

  static prefix = 'organizations' as const;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.name = this.attribute('name', z.string());
    this.createdAt = this.attribute('createdAt', z.string().datetime());
    this.updatedAt = this.attribute('updatedAt', z.string().datetime());
  }

  get projects() {
    return this.hasMany(Project, 'organizationId');
  }

  get organizationMemberships() {
    return this.hasMany(OrganizationMembership, 'organizationId');
  }

  get companyMemberships() {
    return this.hasMany(CompanyMembership, 'organizationId');
  }

  get companies() {
    return this.companyMemberships.map((membership) => membership.company);
  }
}
