import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { ObjectPool, RegisteredModelPrefix } from '../object-pool';
import { PunchListItem } from '../punch-list-item';
import { User } from '../user/model';

export const activityLogTypes = [
  'add_comment',
  'add_thread',
  'resolve_thread',
  'reopen_thread',
  'add_issue',
  'add_user_to_issue',
  'remove_user_from_issue',
  'markup_session',
  'punch_list_item_create',
  'punch_list_item_open',
  'punch_list_item_in_review',
  'punch_list_item_resolved',
] as const;

export type ActivityLogType = (typeof activityLogTypes)[number];

export class ActivityLog extends ApplicationRecord {
  id: string;
  userId: string;
  createdAt: string;
  type: ActivityLogType;
  punchListItemId?: string | null | undefined;

  static prefix = 'activityLogs' as const;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id', z.string());
    this.userId = this.attribute('userId', z.string());
    this.createdAt = this.attribute('createdAt', z.string().datetime());
    this.type = this.attribute('type', z.enum(activityLogTypes));
    this.punchListItemId = this.attribute(
      'punchListItemId',
      z.string().nullish()
    );
  }

  get user() {
    return this.belongsTo(User, this.userId);
  }

  get punchListItem() {
    return this.belongsToOptional(PunchListItem, this.punchListItemId);
  }
}
