import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { Company } from '../company/model';
import { ObjectPool, RegisteredModelPrefix } from '../object-pool';
import { Organization } from '../organization/model';
import { User } from '../user/model';

export class CompanyMembership extends ApplicationRecord {
  userId: string;
  companyId: string;
  organizationId: string;
  role: string;
  createdAt: string;
  updatedAt: string;

  static prefix = 'companyMemberships' as const;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.userId = this.attribute('userId', z.string());
    this.companyId = this.attribute('companyId', z.string());
    this.organizationId = this.attribute('organizationId', z.string());
    this.role = this.attribute('role', z.string());
    this.createdAt = this.attribute('createdAt', z.string().datetime());
    this.updatedAt = this.attribute('updatedAt', z.string().datetime());
  }

  get company() {
    return this.belongsToOptional(Company, this.companyId);
  }

  get user() {
    return this.belongsToOptional(User, this.userId);
  }

  get organization() {
    return this.belongsToOptional(Organization, this.organizationId);
  }
}
