import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { DrawingSet } from '../drawing-set/model';
import { ObjectPool, RegisteredModelPrefix } from '../object-pool';

export class DrawingSetPage extends ApplicationRecord {
  pageNumber: number;
  sheetNumber: string | null;
  title: string | null;
  state:
    | 'initialized'
    | 'extracting_metadata'
    | 'awaiting_confirmation'
    | 'awaiting_publish'
    | 'published';
  sourceUrl?: string | null;
  sourceFileName?: string | null;
  pageUrl?: string | null;
  lowResImageUrl?: string | null;
  sheetDate?: string | null;
  organizationId: string;
  drawingSetId: string;
  drawingDisciplineId?: string | null;
  width?: number | null;
  height?: number | null;
  alignmentOffsetX?: number | null;
  alignmentOffsetY?: number | null;
  deletedAt?: string | null;
  pageMeasure?: number | null;
  realMeasure?: number | null;
  measureConversion?: string | null;
  measureShape?: string | null;
  roundToNearest?: string | null;
  createdAt: string;
  updatedAt: string;

  static prefix = 'drawingSetPages' as const;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.pageNumber = this.attribute('pageNumber', z.number().int());
    this.sheetNumber = this.attribute('sheetNumber', z.string().nullable());
    this.title = this.attribute('title', z.string().nullable());
    this.state = this.attribute(
      'state',
      z.enum([
        'initialized',
        'extracting_metadata',
        'awaiting_confirmation',
        'awaiting_publish',
        'published',
      ])
    );
    this.sourceUrl = this.attribute('sourceUrl', z.string().nullable());
    this.sourceFileName = this.attribute(
      'sourceFileName',
      z.string().nullable()
    );
    this.pageUrl = this.attribute('pageUrl', z.string().nullable());
    this.lowResImageUrl = this.attribute(
      'lowResImageUrl',
      z.string().nullish()
    );
    this.sheetDate = this.attribute('sheetDate', z.string().nullable());
    this.organizationId = this.attribute('organizationId', z.string());
    this.drawingSetId = this.attribute('drawingSetId', z.string());
    this.drawingDisciplineId = this.attribute(
      'drawingDisciplineId',
      z.string().nullable()
    );
    this.width = this.attribute('width', z.number().int().nullable());
    this.height = this.attribute('height', z.number().int().nullable());
    this.alignmentOffsetX = this.attribute(
      'alignmentOffsetX',
      z.number().nullish()
    );
    this.alignmentOffsetY = this.attribute(
      'alignmentOffsetY',
      z.number().nullish()
    );
    this.deletedAt = this.attribute(
      'deletedAt',
      z.string().datetime().nullish()
    );
    this.pageMeasure = this.attribute('pageMeasure', z.number().nullish());
    this.realMeasure = this.attribute('realMeasure', z.number().nullish());
    this.measureConversion = this.attribute(
      'measureConversion',
      z.string().nullish()
    );
    this.measureShape = this.attribute('measureShape', z.string().nullish());
    this.roundToNearest = this.attribute(
      'roundToNearest',
      z.string().nullish()
    );
    this.createdAt = this.attribute('createdAt', z.string().datetime());
    this.updatedAt = this.attribute('updatedAt', z.string().datetime());
  }

  get drawingSet() {
    return this.belongsTo(DrawingSet, this.drawingSetId);
  }

  get drawingArea() {
    return this.drawingSet?.drawingArea;
  }
}
