import { compact } from '@/lib/query';
import { ReadonlyJSONObject } from '@rocicorp/rails';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { CompanyMembership } from '../company_membership/model';
import { ObjectPool, RegisteredModelPrefix } from '../object-pool';
import { Organization } from '../organization/model';

export class Company extends ApplicationRecord {
  organizationId: string;
  name: string;
  url?: string | null;
  createdAt: string;
  updatedAt: string;

  static prefix = 'companies' as const;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.organizationId = this.attribute('organizationId', z.string());
    this.name = this.attribute('name', z.string());
    this.url = this.attribute('url', z.string().nullish());
    this.createdAt = this.attribute('createdAt', z.string().datetime());
    this.updatedAt = this.attribute('updatedAt', z.string().datetime());
  }

  get organization() {
    return this.belongsToOptional(Organization, this.organizationId);
  }

  get companyMemberships() {
    return this.hasMany(CompanyMembership, 'companyId');
  }

  get users() {
    return compact(
      this.companyMemberships.map((membership) => membership.user)
    );
  }
}
