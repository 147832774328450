import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { SubmittalApproverDocument } from './submittal-approver-document';
import { SubmittalStage } from './submittal-stage';
import { User } from './user/model';

export const submittalApproverResponseTypes = [
  'no_exceptions_taken',
  'approved_as_noted',
  'revise_and_resubmit',
  'rejected',
] as const;
export type SubmittalApproverResponseType =
  (typeof submittalApproverResponseTypes)[number];
export const submittalApproverResponseTypeLabels = {
  no_exceptions_taken: 'No Exceptions Taken',
  approved_as_noted: 'Approved As Noted',
  revise_and_resubmit: 'Revise And Resubmit',
  rejected: 'Rejected',
};
export const submittalApproverStatuses = [
  'rejected',
  'approved',
  'pending',
] as const;
export type SubmittalApproverStatus =
  (typeof submittalApproverStatuses)[number];
export const responseTypesConsideredApproved = [
  'no_exceptions_taken',
  'approved_as_noted',
];
export const responseTypesConsideredRejected = [
  'revise_and_resubmit',
  'rejected',
];
export class SubmittalApprover extends ApplicationRecord {
  id: string;
  createdAt: string;
  updatedAt: string;
  submittalStageId: string;
  userId: string;
  respondedAt?: string | null | undefined;
  responseType?: SubmittalApproverResponseType | null | undefined;
  status?: SubmittalApproverStatus | null | undefined;
  comment?: string | null | undefined;

  static prefix = 'submittalApprovers' as const;

  static schema = z.object({
    id: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    submittalStageId: z.string(),
    userId: z.string(),
    respondedAt: z.string().datetime().nullish(),
    responseType: z.enum(submittalApproverResponseTypes).nullish(),
    status: z.enum(submittalApproverStatuses).nullish(),
    comment: z.string().nullish(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.submittalStageId = this.attribute('submittalStageId');
    this.userId = this.attribute('userId');
    this.respondedAt = this.attribute('respondedAt');
    this.responseType = this.attribute('responseType');
    this.status = this.attribute('status');
    this.comment = this.attribute('comment');
  }

  get submittalStage() {
    return this.belongsTo(SubmittalStage, this.submittalStageId);
  }

  get user() {
    return this.belongsTo(User, this.userId);
  }

  get documents() {
    return this.hasMany(SubmittalApproverDocument, 'submittalApproverId');
  }

  get rejected() {
    if (!this.responseType) return false;

    return responseTypesConsideredRejected.includes(this.responseType);
  }

  get approved() {
    if (this.rejected) return false;
    if (!this.responseType) return false;

    return responseTypesConsideredApproved.includes(this.responseType);
  }
}
